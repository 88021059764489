<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo.displayName">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ loggedUser }}</p>
      <small></small>
    </div>
    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <img v-if="activeUserInfo.photoURL" key="onlineImg" :src="activeUserInfo.photoURL" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>
      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>
          <!--
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Inbox</span>
          </li>
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Tasks</span>
          </li>
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Chat</span>
          </li>
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="HeartIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Wish List</span>
          </li>
          -->
          <vs-divider class="m-1" />
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2" @click="doLogout">Salir</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      loggedUser: localStorage.getItem("userInfo").slice(0,localStorage.getItem("userInfo").length - 1).slice(1)
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    ...mapActions('AuthenticationStore', ['logout']),
    doLogout() {

      this.logout()
        .then(() => {
          // This is just for demo Purpose. If user clicks on logout -> redirect
          this.$router.push('/pages/login').catch(() => {});
        })
        .catch(err => {
          this.$log.error(err);
        });
    },
    checkUser: function() {
      var currentActive = Date.now();
      var lastActive = localStorage.getItem("lastActive");
      if (!localStorage.getItem("lastActive")) {
        localStorage.setItem("lastActive", currentActive);
        this.doLogout();
      } else {
        var diffTime = Math.abs(lastActive - currentActive);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60)); 
        if (diffDays>6) {
          localStorage.removeItem("lastActive");
          this.doLogout();
        }
      }
    },
  },
  beforeMount() {
    this.checkUser();
  },
};

</script>
