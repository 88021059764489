/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default [
  {
    url: "/",
    name: "Proyectos",
    slug: "Proyectos",
    icon: "HomeIcon",
  },
  {
    url: "/quotations",
    name: "Cotizaciones",
    slug: "Cotizaciones",
    icon: "BookIcon",
  },
  {
    url: "/bookings",
    name: "Reservas",
    slug: "Reservas",
    icon: "Edit2Icon",
  },
  {
    url: "/promises",
    name: "Promesas",
    slug: "Promesas",
    icon: "FileTextIcon",
  },
  {
    url: "/calendar",
    name: "Calendario",
    slug: "Calendario",
    icon: "CalendarIcon",
  },
  {
    url: "/discounts",
    name: "Descuentos",
    slug: "Descuentos",
    icon: "DollarSignIcon",
  },
  {
    url: "/clients",
    name: "Clientes",
    slug: "Clientes",
    icon: "UserIcon",
  },
]
